

















































































































































































































































































































































@use "@material/data-table/data-table";
@include data-table.core-styles;

.mdc-data-table__header-cell,
.mdc-data-table__cell {
  color: var(--color-text-primary);
  background-color: var(--color-active-surface);
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;}

.mdc-data-table {
  width: 100%;}

.mdc-data-table__row,
.mdc-data-table__cell {
  background-color: var(--color-surface);
  color: #B00020;}
.simulator-table {
  margin: 19px 44px 24px;
}
.simulador-highchart {
  margin: 20px 10px;
  border: 0.5px solid var(--color-text-secondary);
}
.info-ticker-box {
   width: 100%;
   display: flex;
   justify-content: center;
}
.text-info-ticker {
  text-align: center;
  background-color: var(--color-surface);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
  padding: 7px 25px;

  span {
    font-size: 18px;
  }
}
.text-bold {
  font-weight: bold;
}

.simulator-button-add {
  background-color: #00AEDD;
  border-radius: 50%;
  color: #FFFFFF;
  border: none;
  margin-left: 20px;
  padding: 3px 5px 1px;
  box-shadow: 0px 1px 3px gray;
  cursor: pointer;
}

.simulator-chips-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin: 22px 10px 0;
}

.simulator-chips-box {
  display: flex;
  margin: 0 6px 12px;
  font-size: 14px;
  line-height: 20px;
  border: 0.5px solid rgba(0, 0, 0, 0.62);
  color: var(--color-text-secondary);
  border-radius: 16px;
  padding: 5px 10px;
  cursor: default;
  background-color: var(--color-surface);
}

.mdc-button {
  margin-top: 20px;
}

.icon-delete {
  cursor: pointer;
  font-size: 20px;
  color: var(--color-text-secondary);
  margin-left: 10px;
}

.simulador-button-save-index {
  text-align: center;
}

hr {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 20px 37px 43px;;
}

.simulator-title-index {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #0F0F1A;
  margin-left: 37px;
}

.simulator-chips-margin {
  margin: 5px 30px 30px;
}

.simulador-input-autocomplete {
  text-align: center;
}

.label-autocomplete-input {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--color-text-secondary);
}
.control {
  margin: 0;
}

#highchart-content {
  height: 330px;
  width: 100%;
}
.highcharts-root {
  width: 380px !important;
}

#portfolio-calculator{
  overflow-y: hidden;
}
#portfolio-calculator-fullscreen {
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  z-index: 999999;
  left: 0px;
}
.pop-up{
  position: absolute;
  top: 30%;
  left: 35%;
  height: 35%;
  width: 33%;
  background-color: white;
  box-shadow: 6px 6px 6px 3px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.close{
  position: absolute;
  top: 27%;
  left: 68.5%;
  height: 2%;
  width: 2%;
  text-align: center;
  cursor: pointer;
}
.input-container-size{
  width: 75%;
  margin: 0 0 0 12.5%;
}
.mdc-button {
  margin: 10px 0;
  width: 40%;
}
