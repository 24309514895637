


























































































































































































































































.autocomplete-portal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 9999;
  overflow: visible;
  pointer-events: none;
}

.dropdown-container {
  position: absolute;
  margin-top: 0;
  overflow: visible;
  pointer-events: auto;
}

.options-list {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--color-background, white);
  border-radius: 8px;
  box-shadow: 0 6px 16px rgba(15, 15, 26, 0.12);
  padding: 6px 0;
  list-style: none;
  margin: 0;
  border: 1px solid var(--color-outline, rgba(15, 15, 26, 0.12));
  box-sizing: border-box;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--color-surface, #F3F4F7);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--color-outline, rgba(15, 15, 26, 0.2));
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--color-text-secondary, #606577);
  }
}

.options-list li {
  width: 100%;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.15s ease;
  border-bottom: 1px solid rgba(15, 15, 26, 0.05);
  &:last-child {
    border-bottom: none;
  }
  &:hover, &.highlighted {
    background-color: var(--color-active-surface, #d9ecf3);
  }
}

.no-results {
  width: 100%;
  padding: 16px;
  background-color: var(--color-background, white);
  border-radius: 8px;
  box-shadow: 0 6px 16px rgba(15, 15, 26, 0.12);
  text-align: center;
  border: 1px solid var(--color-outline, rgba(15, 15, 26, 0.12));
  box-sizing: border-box;
  
  p {
    color: var(--color-text-secondary, #606577);
    font-style: italic;
    margin: 0;
    font-size: 14px;
  }
}

/* Animation */
.dropdown-enter-active, .dropdown-leave-active {
  transition: all 0.25s ease;
}
.dropdown-enter, .dropdown-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
